import React, { lazy, Suspense } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader } from "helper";
import { Redirect } from "react-router-dom";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));

export const Main = (props) => {
	return (
		<Router basename="/">
			<div id="Main" className="main-panel">
				<ErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={false} type="landscape" />
						<Loader loader={props?.main?.loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/">
									<Redirect to="/ms7"/>
								</Route>
								<Route exact path="/:id" component={Home} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</Suspense>
				</ErrorBoundary>
			</div>
		</Router>
	);
};

export default Main;
